<template>
  <div class="pb-2">
    <b-form-group>
      <b-form-input
        v-model="searchTerm"
        class="d-md-none"
        type="text"
        :placeholder="activeTab === 'users' ? $t('Search Users') : $t('Search Permissions')"
      />
    </b-form-group>

    <b-tabs>
      <b-tab
        :title="$t('permissions.permissions')"
        class="position-relative"
        :lazy="lazyPermission"
        @click="changeTab('permissions')"
      >
        <permissions-list
          :search-term="activeTab === 'permissions' ? searchTerm : ''"
          :can-manage-permissions="canManagePermissions"
        />
      </b-tab>

      <b-tab
        :title="$t('Users')"
        :lazy="lazyUser"
        @click="changeTab('users')"
      >
        <users-list
          :search-term="activeTab === 'users' ? searchTerm : ''"
          :can-manage-permissions="canManagePermissions"
          @result="options => usersOptions = options"
          @updated="() => lazyPermission = true"
        />
      </b-tab>

      <template #tabs-end>
        <div class="d-flex position-absolute right-0">
          <div class="search d-none d-md-block">
            <b-form-group
              :label="$t('Search')"
              label-cols="3"
            >
              <b-form-input
                v-model="searchTerm"
                type="text"
                :placeholder="activeTab === 'users' ? $t('Search Users') : $t('Search Permissions')"
              />
            </b-form-group>
          </div>
          <manage-permissions
            v-if="canManagePermissions"
            :permissions="permissions"
            class="ml-1"
          />
        </div>
      </template>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import ManagePermissions from '@/views/common/components/ManagePermissions.vue'
import { mapGetters } from 'vuex'
import PermissionsList from './components/PermissionsList.vue'
import UsersList from './components/UsersLists.vue'

export default {
  components: {
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    PermissionsList,
    UsersList,
    ManagePermissions,
  },
  data() {
    return {
      activeTab: 'permissions',
      searchTerm: '',
      lazyUser: true,
      lazyPermission: false,
      permissionsOptions: [],
      usersOptions: [],
      permissions: ['permissions.manage'],
    }
  },
  computed: {
    ...mapGetters('permissions', ['canManagePermissions']),
  },
  methods: {
    changeTab(tab) {
      if (tab === 'users') this.lazyUser = false
      this.activeTab = tab
      this.searchTerm = ''
    },
  },
}
</script>
